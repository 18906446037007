<template>
  <CarouselItem :slides="items" :delay="8000" />
</template>

<script>
import CarouselItem from "@/components/Carousel/CarouselItem";

export default {
  name: "App",
  components: {
    CarouselItem,
  },
  data() {
    return {
      items: [
        {
          image: "./static/Frelectrics.png",
          title: "Frelectrics",
          description: "",
        },
        {
          image: "./static/GinoAnnys.png",
          title: "Gino Annys",
          description: "",
        },
        {
          image: "./static/Bellezza.png",            
          title: "Where can I get some?",
          description:
            "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
        },
       /* {
          image: "./static/BwWim.jpg",            
          title: "Where can I get some?",
          description:
            "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
        },*/
        {
          image:"./static/KoenVanhixe.png",
            
          title: "Where can I get some?",
          description:
            "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
        },
        {
          image: "./static/PleisterwerkHenry.png",
          title: "Where can I get some?",
          description:
            "",
        },
        /*{
          image: "./static/BwTim.jpg",
          title: "Where can I get some?",
          description:
            "",
        },*/
        {
          image: "./static/KristofDeJaegher.png",            
          title: "Where can I get some?",
          description:
            "",
        },
        {
          image: "./static/SlagerijStefaan.png",            
          title: "Where can I get some?",
          description:
            "",
        },
        {
          image: "./static/TerHaeghe.png",            
          title: "Where can I get some?",
          description:
            "",
        },
        /*{
          image: "./static/BwJohny.jpg",
          title: "Frelectrics",
          description: "",
        },*/
        {
          image:"./static/LucaScreen.png",            
          title: "Where can I get some?",
          description:
            "s",
        },
        {
          image:"./static/Kim.png",            
          title: "Where can I get some?",
          description:
            "",
        },
        {
          image:"./static/IProjects.png",            
          title: "Where can I get some?",
          description:
            "",
        },
        /*{
          image: "./static/BwPeter.jpg",
          title: "Frelectrics",
          description: "",
        },*/
        {
          image: "./static/Beuselinck.png",
          title: "Frelectrics",
          description: "",
        },
        {
          image: "./static/Degrave.png",
          title: "Frelectrics",
          description: "",
        },
        {
          image: "./static/ProTextiel.png",
          title: "Frelectrics",
          description: "",
        }
      ],
    };
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Open Sans", sans-serif;
}
</style>
